import Image from "@/components/Atoms/Image";
import styles from "./index.module.scss";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { CopyCard } from "@/components/Molecules/CopyCard";
import { useRouter } from "next/router";
import {
	getPortfolioSlug,
	pageRelatedLink,
	string_to_slug,
} from "@/lib/functions";
import { FeaturedBannersGallery } from "@/components/Molecules/FeaturedBannersGallery";
import { OverlayBackground } from "@/components/Atoms/OverlayBackground";
import { ParallaxImage } from "@/components/Atoms/ParallaxImage";
import PillGallery from "./PillGallery";
import { Shape } from "@/components/Atoms/Spacer";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import React from "react";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { routerHandler } from "@/lib/routes/router-links-handler";

export default function SectionTextGallery(data: any) {
	const { locale }: any = useRouter();

	const portfolioSlug =
		getPortfolioSlug(data?.portfolioSlug, locale) || data?.portfolioSlug;

	const [click, setClick] = React.useState(false);
	function loadIframe() {
		setClick(!click);
	}

	if (data.swapImagesForFeaturedBannersGallery) {
		return (
			<div
				id={string_to_slug(data.title)}
				style={{ backgroundColor: data.bgColor?.color.hex }}
				className={` ${
					data.useSectionMargins ? "py-6 md:py-20 lg:py-32 " : "py-0"
				} relative w-full`}
			>
				{data.addSpacer && (
					<Shape
						invert={data.invertSpacer}
						shape={data.spacerConfiguration?.customShape}
						color={data.overlayBgColor || data.bgColor}
						direction={data.spacerConfiguration?.spacerDirection}
						icon={[
							data.spacerConfiguration?.addIconElement,
							data?.spacerConfiguration?.spacerIcon,
						]}
						position={data?.spacerConfiguration?.spacerPosition}
					/>
				)}
				{data.addOverlayBgColor ? (
					<OverlayBackground
						type={data.overlayStyle}
						color={data.overlayBgColor?.color}
						bg={data.background}
						layout={data.layout}
					/>
				) : (
					<>
						{data.textLayoutCard != "bgImage" ? (
							<FullBackgroundImage data={data.background} />
						) : (
							<></>
						)}
					</>
				)}

				<div
					className={`${
						data.sectionLayout === "container"
							? "max-w-[1360px]  "
							: "max-w-[1860px]"
					}  ${data.addSpacer ? "pt-20" : ""} mx-auto`}
				>
					<div
						className={`${
							data.sectionLayout === "container" ? "container " : ""
						}  mx-auto relative w-full ${
							data.itemsAlignment === "center" ? "justify-items-center" : ""
						} grid grid-cols-3 gap-2 lg:gap-10 px-6 lg:px-0`}
						style={{
							backgroundColor:
								data.textLayoutCard == "bgFullColor"
									? data.cardBackgroundColor?.color.hex
									: "transparent",
						}}
					>
						{data.featuredBannerStyle == "pill" ? (
							<>
								<PillGallery
									locale={locale}
									portfolioSlug={portfolioSlug}
									data={data}
								/>
							</>
						) : (
							<>
								{!data.hideCard && (
									<div
										className={` ${
											data.textLayoutCard == "bgColor" ? "mb-12 lg:mb-20" : ""
										}
                     ${
												data.layout == "fullRow"
													? "col-span-3 pt-6 pb-6"
													: "col-span-3 lg:col-span-1"
											} flex flex-col gap-y-4 relative z-1 `}
									>
										<CopyCard
											title={data.title}
											description={data.description}
											color={data.textColor?.color}
											colorTitle={data.titleColor?.color}
											underline={data.addTitleUnderline}
											underlineColor={data.underlineColor?.color}
											customUnderlineAsset={data.customUnderlineAsset}
											layout={data.layout}
											card={data.textLayoutCard}
											cardColor={data.cardBackgroundColor?.color}
											align={data.textAlignment}
											tag="h3"
											label={[
												data.addLabel,
												data.titleLabel,
												data.labelColor?.color,
											]}
											pageRelated={data.pageRelated}
											portfolioSlug={portfolioSlug}
											bg={data.background}
											removeCTA={data.removeCtaFromCard}
										/>
										{data.addParallaxImage && (
											<ParallaxImage
												className="flex lg:hidden items-end"
												data={data.parallaxImage}
											/>
										)}
									</div>
								)}
								<div
									className={`relative z-50  ${
										data.layout == "fullRow" ? "lg:py-6" : "lg:py-12"
									}  "w-full  ${
										data.layout == "fullRow"
											? "col-span-3  "
											: "col-span-3 lg:col-span-2 py-4 lg:translate-y-[0]"
									}  overflow-x-auto lg:overflow-visible pb-12`}
								>
									<div
										className={`relative w-full ${
											data.hideCard ? "pt-12" : ""
										}   lg:pb-4 flex flex-row gap-2 md:gap-4 flex-nowrap lg:justify-center`}
									>
										{data.galleryBackground && (
											<div className="absolute w-full h-full lg:flex justify-center items-center hidden">
												<FullBackgroundImage
													data={data.galleryBackground}
													className="-translate-y-7"
												/>
											</div>
										)}
										{data.featuredBannersGallery.map(
											(entry: any, i: number) => {
												const geo = entry?.geo[0];
												return (
													<GeoWrapper
														key={i}
														initial={geo?.countryAvailability}
														country={geo?.countries}
														list={geo?.listOfCountries}
													>
														<FeaturedBannersGallery
															key={i}
															entry={entry}
															locale={locale}
															portfolioSlug={portfolioSlug}
															card={data.textLayoutCard}
															layout={data.layout}
															ctaConfig={[
																data?.addProductFeaturedBannerCallToAction,
																data?.galleryCtaConfiguration,
															]}
															labelLayout={data.labelLayout}
															underline={entry.addTitleUnderline}
															underlineColor={entry.underlineColor?.color}
															customUnderlineAsset={entry.customUnderlineAsset}
															textColor={data.featuredBannerTextColor?.color}
															styleColor={
																data.featuredBannerStyleColor?.color.hex
															}
															buttonColor={
																data?.featuredBannerButtonColor?.color
															}
															buttonText={
																data?.featuredBannerButtonTextColor?.color
															}
															style={data.featuredBannerStyle}
														/>
													</GeoWrapper>
												);
											}
										)}
									</div>
									{data.addParallaxImage && (
										<ParallaxImage
											className="hidden lg:flex items-center translate-y-[-10%]"
											data={data.parallaxImage}
										/>
									)}
								</div>
							</>
						)}
						{data.pageRelated?.length > 0 && data.removeCtaFromCard && (
							<div className="col-span-3">
								{data.pageRelated[0].linksToIframe ? (
									<ButtonMaster
										onClick={() => loadIframe()}
										anim={"slideLeft"}
										type={data.pageRelated[0]?.buttonStyle}
										cta={data.pageRelated[0]?.typeColor?.color}
										color={data.pageRelated[0]?.labelColor?.color}
										customIcon={data.pageRelated[0]?.icon?.img}
										customColor={data.pageRelated[0]?.iconColor?.color}
										target={``}
										link={``}
									>
										{data.pageRelated[0]?.buttonLabel?.label}
									</ButtonMaster>
								) : (
									<>
										<ButtonMaster
											anim={"slideLeft"}
											type={data.pageRelated[0]?.buttonStyle}
											cta={data.pageRelated[0]?.typeColor?.color}
											color={data.pageRelated[0]?.labelColor?.color}
											customIcon={data.pageRelated[0]?.icon?.img}
											customColor={data.pageRelated[0]?.iconColor?.color}
											target={`${
												data.pageRelated[0]?.page ? `_self` : `_blank`
											}`}
											link={` ${
												data.pageRelated[0]?.page
													? ` ${routerHandler({
															typename: data.pageRelated[0]?.__typename,
															slug: pageRelatedLink(
																data.pageRelated[0]?.page,
																locale,
																data.pageRelated[0]?.sectionAnchor?.title
															),
															locale: locale,
													  })}`
													: `${data.pageRelated[0]?.externalUrl}`
											}`}
										>
											{data.pageRelated[0]?.buttonLabel?.label}
										</ButtonMaster>
									</>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<>
				<div
					className={`  w-full ${
						data.useSectionMargins ? "py-6 md:py-12 lg:py-24" : "py-0"
					} `}
				>
					<div
						style={{ backgroundColor: data.bgColor?.color.hex }}
						className={"relative"}
					>
						{data.addSpacer && (
							<Shape
								invert={data.invertSpacer}
								shape={data.spacerConfiguration?.customShape}
								color={data.overlayBgColor || data.bgColor}
								direction={data.spacerConfiguration?.spacerDirection}
								icon={[
									data.spacerConfiguration?.addIconElement,
									data?.spacerConfiguration?.spacerIcon,
								]}
								position={data?.spacerConfiguration?.spacerPosition}
							/>
						)}
						<div
							className={`   overflow-hidden top-0 left-0 absolute w-full h-full z-0`}
						>
							<Image
								data={data.background}
								className={`${styles.textGalleyBg} cursor-pointer absolute w-full h-full`}
							/>
						</div>
						<div className="container mx-auto relative w-full flex flex-row justify-center py-12 lg:py-24 px-6 lg:px-12">
							<div className="grid grid-cols-1 xl:grid-cols-2 justify-center items-center">
								<div
									className={`${
										data.textAlignment === "left"
											? "order-1"
											: "order-2 items-center lg:items-end"
									} col-span-1  flex flex-col gap-y-4`}
								>
									<CopyCard
										title={data.title}
										description={data.description}
										color={data.textColor?.color}
										underline={data.addTitleUnderline}
										underlineColor={data.underlineColor?.color}
										customUnderlineAsset={data.customUnderlineAsset}
										layout={data.layout}
										card={data.textLayoutCard}
										cardColor={data.cardBackgroundColor?.color}
										align={data.textAlignment}
										tag="h2"
										pageRelated={data.pageRelated}
										label={[data.addLabel, data.titleLabel]}
									/>
								</div>
								<div
									className={`${
										data.textAlignment === "left" ? "order-2" : "order-1"
									} col-span-1 mt-12 overflow-hidden`}
								>
									<div className="block max-w-[75%] md:max-w-2xl mx-auto min-h-[15rem] lg:min-h-[30rem]">
										{data.gallery.map((entry: any, i: number) => {
											const w = `w-[${9 + i * 2}%]`;
											const scale = `scale-[${60 + i * 5}%]`;
											const blur = `blur-[${5 - i}px]`;
											const top = `-top-[${45 - i * 5}%]`;
											const left = `left-[${20 + i * 10}%]`;
											const ml = i == 0 ? "ml-[15%]" : `-ml-[${1 + i * 0.5}%]`;

											return (
												<LazyMotion key={i} features={domAnimation}>
													<m.div
														initial={{
															opacity: 0,
															width: `15%`,
														}}
														whileInView={{
															opacity: 1,
															width: `${9 + i * 2}%`,
															transition: { duration: 1, delay: 0.5 },
														}}
														viewport={{ once: true }}
														key={i}
														className={`relative group inline-block align-middle ${ml} `}
													>
														<div
															className={`absolute hidden lg:flex opacity-0 group-hover:opacity-100 transition duration-300 z-10 ${top} ${left} ${scale} `}
														></div>
														<Image
															key={i}
															data={entry}
															className={`${blur} hover:blur-none transition duration-300`}
														/>
													</m.div>
												</LazyMotion>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export const fragment = `
    fragment TextGalleryFragment on SectionTextGalleryRecord {
      __typename
      id
      title
      description(markdown: true)
      label
      textAlignment
      addSpacer
      invertSpacer
      spacerConfiguration {
        id
        spacerDirection
        spacerPosition
        customShape {
          img {
            alt
            url
            width
            height
          }
        }
        addIconElement
        spacerIcon {
          img {
            alt
            url
            width
            height
          }
        }
      }
      addLabel
      titleLabel
      label
      useSectionMargins
      sectionLayout
      labelLayout
      layout
      hideCard
	  removeCtaFromCard
	  itemsAlignment
      textLayoutCard
      addTitleUnderline
      addParallaxImage
      parallaxImage{
        url
        width
        height
      }
      addOverlayBgColor
      overlayStyle
      overlayBgColor {
        color {
          hex
        }
      }
      textColor{
        color{
          hex
        }
      }
	  titleColor{
        color{
          hex
        }
      }
      labelColor{
        color{
          hex
        }
      }
      bgColor{
        color{
          hex
        }
      }
      underlineColor{
        color{
          hex
        }
      }
      customUnderlineAsset{
        img{
          url
          width
          height
        }
      }
      cardBackgroundColor{
        color{
          hex
        }
      }
	galleryBackground {
      responsiveImage {
        src
        sizes
        base64
        width
        srcSet
        height
        webpSrcSet
        bgColor
        aspectRatio
      }
    }
      addProductFeaturedBannerCallToAction
      callToActionPage
      featuredBannerStyleColor {
        color {
          hex
        }
      }
      featuredBannerTextColor {
        color {
          hex
        }
      }
  
      pageRelated {
      ...pageRelatedFragment
      }
      galleryCtaConfiguration {
        ...pageRelatedFragment
      }
      gallery {
        id
        alt
        responsiveImage(imgixParams: {w: "137", h: "460", fit: crop, crop: focalpoint, auto: format, q: "85", ar: "137:460"}) {
          ...responsiveImageFragment
        }
        url   
        width
        height
      }
      background {
        id
        alt
        responsiveImage (imgixParams: {q: "65"}) {
          ...responsiveImageFragment
        }      
      }
      swapImagesForFeaturedBannersGallery
      addProductFeaturedBannerCallToAction
      galleryCtaConfiguration {
        ...pageRelatedFragment
      }
      featuredBannerStyle
      featuredBannersGallery {
		... on SectionTextImageRecord {
			__typename
			addLabel
			textLabel
			title
			geo {
				countryAvailability
				listOfCountries
				countries {
					countryCode
				}
			}
			image {
		        responsiveImage(imgixParams: { fit: crop, crop: focalpoint, auto: format, q: "85"}) {
					...responsiveImageFragment
				}
			}
		  }
        ... on ProductConfRecord {
          __typename
          id
          geo {
            countryAvailability
            listOfCountries
            countries {
              countryCode
            }
          }
          customLabel {
            label
          }
          previewColor {
            color {
              hex
              cssRgb
            }
          }
          addTitleUnderline
          underlineColor{
            color{
              hex
            }
          }
          customUnderlineAsset{
            img{
              url
              width
              height
            }
          }
          previewImage {
            responsiveImage {
              alt
              base64
              bgColor
              title
              width
              height
              sizes
              src
              srcSet
              webpSrcSet
              aspectRatio
            }
          }
          productNameLabel
          specificSku
          showVintageYear
          showBuyButton
          showUmbrellaBrand
          showProductTypeSpecific
          showDesignationOfOrigin
          hideCallToAction
		  showAccoladesCount
          product {
            __typename
            id
            slug
            productName


			_allReferencingBrandsProducts(first: "1") {
				id
				siteConfig {
				  websiteName
				}
				accoladesRatings20 {
				  ratingValue
				}
				accoladesRatings100 {
				  ratingValue
				}
				accoladesRatingsYear
				awardsYear
			}

			_allReferencingRatings {
				ratingYear
				rating {
				  ratingValue
				}
			  }
			  _allReferencingAwards {
				contestYear
				rating {
				  ratingValue
				}
			  }
            productTypeSpecific {
              __typename
              slug
              name
            }
            productBrand {
              title
              umbrellaBrand {
                id
                title
              }
            }
            vintageYear
            productRegion {
              name
            }
            productOrigin {
              originDesignation
            }
            factSheetImage {
              responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                ... responsiveImageFragment
              }
            }
            sku {
              __typename
              slug
              buyNow {
                url
                ctaGeolocation {
                  countryAvailability
                  listOfCountries
                  countries {
                    countryCode
                  }
                }
              }
              skuImageGallery {
                responsiveImage {
                  ... responsiveImageFragment
                }
              }
            }
          }
        }
      }
    }
`;
